import {createStore} from 'vuex'
import router from '@/routes'

// Security Service import
import {SecurityRequest} from "@/services/security/requests"
import {SecurityStore} from "@/services/security/store"
import {Toast} from "@/use/toast"
import {CountriesStore} from "@/services/countries/store";
import {StoresStore} from "@/services/stores/store";
import {CategoriesStore} from "@/services/categories/store";
import {NichesStore} from "@/services/niches/store";
import {TypesStore} from "@/services/types/store";
import {BrandsStore} from "@/services/brands/store";
import {UsersStore} from "@/services/users/store";
import {DepartmentsStore} from "@/services/departments/store";

export default createStore({
    state: {
        user: {
            data: {},
            token: localStorage.getItem('app-auth-token') || null
        },

        pageLoading: false,
    },

    getters: {
        user: (state) => {
            return state.user.data
        },

        pageLoading(state){
            return state.pageLoading
        }
    },

    mutations: {
        SET_USER: (state, value) => {
            state.user.data = value.user
            state.user.token = value.accessToken
            localStorage.setItem('app-auth-token', value.accessToken)
        },

        UPDATE_USER: (state, value) => {
            state.user.data = value
        },

        RESET_AUTH: (state) => {
            state.user.data = {}
            state.user.token = null
            localStorage.removeItem('app-auth-token')
        },

        SET_PAGE_LOADING: (state, value) => {
            state.pageLoading = value
        }
    },

    actions: {

        getUser({commit, dispatch}){
            SecurityRequest.getUser()
                .then( response => {
                    commit('UPDATE_USER', response.data)
                })
                .catch( () => {
                    dispatch('resetAuth')
                })
        },

        setUser({commit}, value){
            commit('SET_USER', value.data)
            //  Redirect user to the Home Page
            router.push({ name: 'WelcomePage'})
        },

        resetAuth({commit}){
            commit('RESET_AUTH')
            router.push({ name: 'LoginPage'})
        }
    },

    modules: {
        DepartmentsStore,
        SecurityStore,
        CountriesStore,
        CategoriesStore,
        NichesStore,
        TypesStore,
        BrandsStore,
        StoresStore,
        UsersStore,
        Toast
    }
})
