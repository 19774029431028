const StoreListPage = () => import(/* webpackChunkName: "StoreListPage" */ "@/services/stores/pages/StoreListPage")
const StorePage = () => import(/* webpackChunkName: "StorePage" */ "@/services/stores/pages/StorePage")
const SidebarComponent = () => import(/* webpackChunkName: "SidebarComponent" */ "@/components/common/SidebarComponent")

export const StoresRoutes = [
  {
    path: "/stores",
    name: "StoreListPage",
    components: { default: StoreListPage, sidebar: SidebarComponent },
    meta: {
      authenticated: true,
      PageTitle: process.env.VUE_APP_NAME + " - Store list",
    },
  },

  {
    path: "/store/:id",
    name: "StorePage",
    components: { default: StorePage, sidebar: SidebarComponent },
    meta: {
      authenticated: true,
      PageTitle: process.env.VUE_APP_NAME + " - Store page",
    },
  },
];